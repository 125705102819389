<template>
  <div class="animated fadeIn">
    <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
    <template v-else>
      <b-card>
        <template v-slot:header>
          <h5>Ubah Pengajuan</h5>
        </template>

        <b-form @submit.prevent="onConfirmEditForm">
          <b-row class="form-group mb-4">
            <b-col sm="3">
              <b-form-group id="fg-create-cabang-fpp" label="Cabang" label-for="create-cabang-fpp" class="required">
                <b-form-select size="md" v-model="form.id_cabang" :plain="false">
                  <template v-slot:first>
                    <b-form-select-option disabled :value="null">Pilih Cabang</b-form-select-option>
                  </template>

                  <b-form-select-option v-for="option in optionsCabang" :key="option.id" :value="option.id">
                    {{ option.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group id="fg-create-divisi-fpp" label="Divisi Tujuan" label-for="create-divisi-fpp"
                class="required">
                <b-form-select size="md" v-model="form.id_devisi" :plain="false" disabled>
                  <b-form-select-option v-for="option in optionsDivision" :key="option.DivisiID" :value="option.DivisiID">
                    {{ option.nm_Divisi }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <div v-if="form.id_devisi == 2">
            <b-row>
              <!-- input jenis pengajuan -->
              <b-col sm="4">
                <b-form-group id="fg-create-kategori-jenis-fpp" label="Jenis Pengajuan"
                  label-for="create-kategori-jenis-fpp" class="required">
                  <b-form-select v-model="form.id_kategori_jenis_fpp" size="md" :plain="false">
                    <template v-slot:first>
                      <b-form-select-option disabled :value="null">Pilih Jenis Pengajuan</b-form-select-option>
                    </template>

                    <b-form-select-option v-for="option in optionsKategoriJenis" v-bind:key="option.id"
                      :value="option.id">
                      {{ option.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>

              <!-- input kuantitas -->
              <!-- <template v-if="this.form.id_kategori_jenis_fpp == 2"> -->
              <b-col sm="2">
                <b-form-group id="fg-create-quantity-fpp" label="Kuantitas" label-for="create-quantity-fpp"
                  class="required">
                  <input class="form-control" v-model.trim="form.qty" placeholder="Contoh: 1, 2, 3, ..." type="number" />
                </b-form-group>
              </b-col>
              <!-- </template> -->
            </b-row>

            <b-row>
              <!-- input item -->
              <b-col sm="6">
                <b-form-group id="fg-create-sub-category-fpp" label="Item" label-for="create-sub-category-fpp"
                  class="required">
                  <multiselect placeholder="Pilih Item" label="name" track-by="id" v-model="form.id_kategori_sub_fpp"
                    :show-labels="false" :close-on-select="true" :clear-on-select="false" :multiple="false"
                    :options="optionsItem" @select="myChangeEvent($event)"></multiselect>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- input catatan -->
            <b-row class="form-group">
              <b-col sm="6">
                <b-form-group id="fg-create-catatan-fpp" label="Catatan/Kronologi" label-for="create-catatan-fpp"
                  class="required">
                  <textarea class="textarea form-control" placeholder="Catatan" v-model.trim="form.catatan"
                    v-on:input="check" :rows="4"></textarea>
                  <small v-if="!$v.form.catatan.required" class="text-danger">Kolom tidak boleh kosong</small>
                </b-form-group>
                <p class="text-secondary mt-1" :class="{ help: true, 'text-danger': remaining == 0 }">
                  {{ instruction }}
                </p>
              </b-col>
            </b-row>

            <div class="form-wrapper-btn submit-btn-wrapper mt-5">
              <b-button type="submit" variant="primary" class="btn-labeled" :disabled="this.$v.form.$invalid">
                <span class="btn-label" v-show="!loadingMain"><i class="fa fa-save"></i></span>

                <span class="btn-label" v-show="loadingMain">
                  <b-spinner small variant="light"></b-spinner>
                </span>

                Simpan Perubahan
              </b-button>
            </div>
          </div>
        </b-form>
      </b-card>

      <template v-if="form.id_devisi == 2">
        <b-card>
          <!-- input file pendukung -->
          <b-form @submit.prevent="showSweetAlertUpdateFile" class="mt-4">
            <b-row>
              <b-col sm="6">
                <b-form-group id="fg-create-file-fpp" class="font-weight-bold small"
                  label="Format File (JPG, JPEG, PNG, PDF, MP4)" label-for="create-file-fpp">
                  <span class="upload-btn-wrapper">
                    <b-button type="button" class="btn-labeled" variant="success">
                      <span class="btn-label"><i class="fa fa-paperclip"></i></span>Tambah & Pilih File
                    </b-button>
                    <input ref="fileUploader" type="file" prepend-icon="mdi-camera"
                      accept="image/jpg, image/jpeg, image/png, application/pdf, video/mp4" multiple
                      @change="onCreateFileChange" />
                  </span>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- List File -->
            <b-row v-show="!loadingDetail">
              <b-col cols="12">
                <label class="font-weight-bold">File Pendukung</label>
              </b-col>
              <b-col cols="12">
                <b-container fluid class="p-0 bg-white">
                  <b-row>
                    <b-col sm="3" class="mb-2" v-bind:key="index" v-for="(file, index) in form.files">
                      <div v-if="file.path.split('.').pop() == 'pdf' ||
                        file.path.split(';')[0].split('/')[1] == 'pdf'
                        ">
                        <iframe width="100%" height="300" allowfullscreen :src="file.path"></iframe>
                      </div>

                      <div v-else-if="file.path.split('.').pop() == 'mp4' ||
                        file.path.split(';')[0].split('/')[1] == 'mp4'
                        ">
                        <iframe width="100%" height="300" allowfullscreen :src="file.path"></iframe>
                      </div>

                      <div id="my-image" v-else v-show="!file.loadingFiles">
                        <expandable-image :src="file.path" />
                      </div>

                      <div v-show="file.loadingFiles" class="my-box">
                        <b-spinner variant="secondary" label="Loading..."></b-spinner>
                      </div>

                      <span class="upload-btn-wrapper mt-2">
                        <b-button size="sm" variant="warning" class="btn-labeled">
                          <span class="btn-label"><i class="fa fa-paperclip"></i></span>Ubah & Pilih File
                        </b-button>
                        <input type="file" prepend-icon="mdi-camera"
                          accept="image/jpg, image/jpeg, image/png, application/pdf, video/mp4" @change="
                            onUpdateFileChange(
                              $event,
                              file.id,
                              file.path,
                              index
                            )
                            " />
                      </span>

                      <template v-if="form.files.length > 1">
                        <span class="upload-btn-wrapper mt-2 mr-2 ml-2">
                          <b-button size="sm" variant="danger" class="btn-labeled"
                            @click="showSweetAlertDeleteFile(file.id, index)">
                            <span class="btn-label" v-show="!file.loadingRemove">
                              <i class="fa fa-trash"></i></span>

                            <span class="btn-label" v-show="file.loadingRemove">
                              <b-spinner small variant="light"></b-spinner></span>Hapus
                          </b-button>
                        </span>
                      </template>
                    </b-col>

                    <b-col sm="3" class="mb-2" v-for="(foto, index) in formCreateFile.create_foto" v-bind:key="foto">
                      <div id="my-image">
                        <expandable-image :src="foto" />
                      </div>

                      <span class="upload-btn-wrapper mt-2">
                        <b-button type="button" variant="danger" @click="removeCreateFoto(index)"
                          class="btn-labeled"><span class="btn-label"><i class="fa fa-trash"></i></span>Hapus</b-button>
                      </span>
                    </b-col>

                    <b-col sm="3" class="mb-2" v-for="(video, index) in formCreateFile.create_video" v-bind:key="video">
                      <div>
                        <iframe width="100%" height="300" allowfullscreen :src="video"></iframe>
                      </div>

                      <span class="upload-btn-wrapper mt-2">
                        <b-button type="button" @click="removeCreateVideo(index)" class="btn btn-sm btn-danger"><i
                            class="fa fa-trash"></i> Hapus</b-button>
                      </span>
                    </b-col>
                  </b-row>

                  <div class="form-wrapper-btn submit-btn-wrapper">
                    <template v-if="lengthCreateFoto > 0 || lengthCreateVideo > 0">
                      <b-button type="button" variant="primary" class="btn-labeled" @click="showSweetAlertCreateFile">
                        <span class="btn-label" v-show="!loadingUpload"><i class="fa fa-cloud-upload"></i></span>

                        <span class="btn-label" v-show="loadingUpload">
                          <b-spinner small variant="light"></b-spinner> </span>Unggah File Tambahan
                      </b-button>
                    </template>

                    <template v-else>
                      <b-button variant="primary" class="btn-labeled" disabled>
                        <span class="btn-label"><i class="fa fa-cloud-upload"></i></span>Unggah File Tambahan
                      </b-button>
                    </template>
                  </div>
                </b-container>
              </b-col>
            </b-row>

            <div class="text-center my-5" v-show="loadingDetail">
              <b-spinner class="align-middle" variant="secondary"></b-spinner>
            </div>
          </b-form>
        </b-card>
      </template>
    </template>
  </div>
</template>

<script>
import { validationMixin } from "/node_modules/vuelidate";
import { required } from "/node_modules/vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  data: () => {
    return {
      initStatus: 0,
      limit: 100,
      form: {
        id_memo: null,
        id_devisi: null,
        id_cabang: null,
        id_kategori_jenis_fpp: null,
        id_kategori_fpp: null,
        id_kategori_sub_fpp: null,
        catatan: "",
        qty: null,
        files: [],
        videos: [],
      },
      formCreateFile: {
        create_foto: [],
        create_video: [],
      },
      formUpdateFile: {
        id_files: null,
        update_files: [],
      },
      formDeleteFiles: {
        id_files: null,
      },
      optionsDivision: [],
      optionsKategori: [],
      optionsKategoriJenis: [],
      optionsItem: [],
      optionsCabang: [],
      lastSegment: "",
      loadingMain: false,
      loadingUpload: false,
      loadingDetail: false,
      lengthCreateFoto: 0,
      lengthCreateVideo: 0,
    };
  },
  validations: {
    form: {
      catatan: {
        required,
      },
      files: {
        required,
      },
    },
  },

  computed: {
    instruction: function () {
      return this.form.catatan == ""
        ? "Maksimal: " + this.limit + " Karakter"
        : "Tersisa: " + this.remaining + " Karakter";
    },

    remaining: function () {
      return this.limit - this.form.catatan.length;
    },

    isEmpty: function () {
      return (
        this.formCreateFile.create_files.length === 0 &&
        this.formCreateFile.create_videos.length === 0
      );
    },
  },

  methods: {
    init() {
      this.initStatus = 0;

      const str = window.location.href;
      this.lastSegment = new URL(str).pathname.split("/")[2];

      // Get Sub Kategori
      this.$axios.get("api/internal-memo/kategori-sub/all").then((response) => {
        let datas = response.data.data;

        for (var i = 0; i < datas.length; i++) {
          this.optionsItem.push({
            id: datas[i].id,
            name: datas[i].name,
            id_kategori_fpp: datas[i].id_kategori_fpp,
          });
        }
      });

      // Get Detail Memo
      let getDetail = this.$axios
        .get("api/internal-memo/memo/" + this.lastSegment)
        .then((response) => {
          let datas = response.data.data;

          this.form.id_memo = datas.id;
          this.form.id_cabang = datas.id_cabang;
          this.form.id_devisi = datas.id_devisi;
          this.form.id_kategori_jenis_fpp = datas.id_kategori_jenis_fpp;
          this.form.id_kategori_fpp = datas.id_kategori_fpp;
          this.form.id_kategori_sub_fpp = datas.kategori_sub;
          this.form.qty = datas.qty;
          this.form.catatan = datas.catatan;

          for (let file of datas.memo_file) {
            this.form.files.push({
              flag: file.flag,
              id: file.id,
              id_internal_memo: file.id_internal_memo,
              path: file.path,
              loadingFiles: false,
              loadingRemove: false,
            });
          }
        });

      Promise.all([getDetail])
        .then(() => {
          this.initStatus = 1;
        })
        .catch((error) => {
          console.log(error);
          this.initStatus = -1;
        });

      // Get Kategori
      this.$axios.get("api/internal-memo/kategori/all").then((response) => {
        let datas = response.data.data;

        for (var i = 0; i < datas.length; i++) {
          this.optionsKategori.push({
            id: datas[i].id,
            name: datas[i].name,
            id_kategori_jenis_fpp: datas[i].id_kategori_jenis_fpp,
            sla: datas[i].sla,
          });
        }
      });

      // Get Divisi
      this.$axios.get("api/admin/devisi/all").then((response) => {
        let datas = response.data.data;

        for (var i = 0; i < datas.length; i++) {
          if (datas[i].isFpp == 1) {
            this.optionsDivision.push({
              DivisiID: datas[i].DivisiID,
              kd_Divisi: datas[i].kd_Divisi,
              nm_Divisi: datas[i].nm_Divisi,
              UserInput: datas[i].UserInput,
            });
          }
        }
      });

      // Get Kategori Jenis
      this.$axios
        .get("api/internal-memo/kategori-jenis/all")
        .then((response) => {
          let datas = response.data.data;

          for (var i = 0; i < datas.length; i++) {
            this.optionsKategoriJenis.push({
              id: datas[i].id,
              name: datas[i].name,
            });
          }
        });

      let cabang = localStorage.getItem("cabang");
      let parseCabang = JSON.parse(cabang);

      for (var dataCabang of parseCabang) {
        this.optionsCabang.push({
          id: dataCabang.id,
          name: dataCabang.name,
        });
      }
    },

    //-----------------  Call Back Function File -----------------//
    getDetail: function () {
      this.loadingDetail = true;
      this.lengthCreateFoto = this.formCreateFile.create_foto.length;
      this.lengthCreateVideo = this.formCreateFile.create_video.length;

      const str = window.location.href;
      this.lastSegment = new URL(str).pathname.split("/")[2];

      // Get Detail Memo
      this.$axios
        .get("api/internal-memo/memo/" + this.lastSegment)
        .then((response) => {
          if (response.status == 200) {
            this.loadingDetail = false;
            let datas = response.data.data;

            this.form.id_memo = datas.id;
            this.form.id_cabang = datas.id_cabang;
            this.form.id_devisi = datas.id_devisi;
            this.form.id_kategori_jenis_fpp = datas.id_kategori_jenis_fpp;
            // this.form.id_kategori_fpp = datas.id_kategori_fpp;
            this.form.id_kategori_sub_fpp = datas.kategori_sub;
            this.form.qty = datas.qty;
            this.form.catatan = datas.catatan;
            this.form.files = datas.memo_file;
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingDetail = false;
        });
    },

    //-----------------  Check 100 Character Function -----------------//
    check: function () {
      this.form.catatan = this.form.catatan.substr(0, this.limit);
    },

    // Send ID Kategori FPP Multiselect Function
    myChangeEvent: function (val) {
      this.form.id_kategori_fpp = val.id_kategori_fpp;
    },

    //-----------------  Reset Function -----------------//
    resetFormUpdate: function () {
      this.formCreateFile = {
        create_foto: [],
        create_video: [],
      };

      this.formUpdateFile = {
        id_files: null,
        update_files: [],
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },

    //----------------- Confirm Edit Form Function -----------------//
    onConfirmEditForm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      } else {
        this.showSweetAlertEditForm();
      }
    },

    //----------------- Update Form Function -----------------//
    showSweetAlertEditForm: function () {
      let form = {
        id: this.lastSegment,
        id_cabang: this.form.id_cabang,
        id_kategori_jenis_fpp: this.form.id_kategori_jenis_fpp,
        id_kategori_sub_fpp: this.form.id_kategori_sub_fpp.id,
        id_devisi: this.form.id_devisi,
        // id_kategori_fpp: this.form.id_kategori_fpp,
        catatan: this.form.catatan,
        qty: this.form.qty,
      };

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        html: "Konfirmasi, Pastikan perubahan data sudah benar",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingMain = true;

          this.$axios
            .post("/api/internal-memo/memo/" + this.lastSegment, form)
            .then((response) => {
              if (response.status == 200) {
                this.loadingMain = false;
                Toast.fire({
                  // icon: "success",
                  iconHtml: `
                  <div class="m-2">
                    <i class="fa fa-check"></i>
                  </div>
                `,
                  iconColor: '#a5dc86',
                  html: "Selamat! Formulir pengajuan Anda berhasil disimpan",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.loadingMain = false;
              Toast.fire({
                title: "Gagal",
                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                iconColor: '#dc3545',
                html: "Formulir pengajuan Anda gagal disimpan",
              });
            });
        }
      });
    },

    //----------------- Show Add File Function in Thumbnail -----------------//
    onCreateFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      this.createFile(files);
    },

    createFile(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();

        reader.onload = function (event) {
          const imageUrl = event.target.result;
          const extBase64 = imageUrl.split(";")[0].split("/")[1];

          if (
            extBase64 == "jpeg" ||
            extBase64 == "jpg" ||
            extBase64 == "png" ||
            extBase64 == "pdf"
          ) {
            vm.formCreateFile.create_foto.push(imageUrl);
          } else if (extBase64 == "mp4") {
            vm.formCreateFile.create_video.push(imageUrl);
          }

          vm.lengthCreateFoto = vm.formCreateFile.create_foto.length;
          vm.lengthCreateVideo = vm.formCreateFile.create_video.length;
        };
        reader.readAsDataURL(files[index]);
      }
    },

    removeCreateFoto: function (index) {
      this.formCreateFile.create_foto.splice(index, 1);
      this.$refs.fileUploader.value = "";
      this.lengthCreateFoto = this.formCreateFile.create_foto.length;
    },

    removeCreateVideo: function (index) {
      this.formCreateFile.create_video.splice(index, 1);
      this.$refs.fileUploader.value = "";
      this.lengthCreateVideo = this.formCreateFile.create_video.length;
    },

    //----------------- Create File Function -----------------//
    showSweetAlertCreateFile: function () {
      var idMemo = this.form.id_memo;

      var formCreateFile = {
        files: this.formCreateFile.create_foto,
        videos: this.formCreateFile.create_video,
      };

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        html: "Silahkan konfirmasi terlebih dahulu",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loadingUpload = true;

          this.$axios
            .post(
              "/api/internal-memo/memo/addNewFile/" + idMemo,
              formCreateFile
            )
            .then((response) => {
              if (response.status == 200) {
                this.loadingUpload = false;
                Toast.fire({
                  // icon: "success",
                  iconHtml: `
                  <div class="m-2">
                    <i class="fa fa-check"></i>
                  </div>
                `,
                  iconColor: '#a5dc86',
                  html: "Selamat! File Anda berhasil disimpan",
                });
              }
              this.formCreateFile.create_foto = [];
              this.formCreateFile.create_video = [];
              this.getDetail();
            })
            .catch((error) => {
              console.log(error);
              this.loadingUpload = false;
              Toast.fire({
                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                iconColor: '#dc3545',
                html: "File Anda gagal disimpan. Silahkan coba lagi",
              });
            });
        }
      });
    },

    //----------------- Show Update File Function in Thumbnail -----------------//
    onUpdateFileChange(event, id, path, indeks) {
      this.formUpdateFile.id_files = id;

      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.updateFile(files, id, indeks);
    },

    updateFile(files, id, indeks) {
      var check = this.form.files.find((item) => item.id === id);

      if (check) {
        var vm = this;
        for (var index = 0; index < files.length; index++) {
          var ext = files[index].name.split(".").pop();
          var reader = new FileReader();

          reader.onload = function (event) {
            const imageUrl = event.target.result;

            vm.formUpdateFile.update_files.push(imageUrl);

            // Submit File Function
            vm.showSweetAlertUpdateFile(
              ext,
              indeks,
              check.flag,
              check.id,
              check.id_internal_memo,
              imageUrl
            );
          };
          reader.readAsDataURL(files[index]);
        }
      }
    },

    //----------------- Update File Function -----------------//
    showSweetAlertUpdateFile: function (
      ext,
      indeks,
      flag,
      id,
      id_memo,
      image_url,
      path
    ) {
      var idFiles = this.formUpdateFile.id_files;
      var formUpdateFile = "";

      if (ext == "jpeg" || ext == "jpg" || ext == "png" || ext == "pdf") {
        formUpdateFile = {
          files: this.formUpdateFile.update_files,
        };
      } else if (ext == "mp4") {
        formUpdateFile = {
          videos: this.formUpdateFile.update_files,
        };
      }

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.$swal({
        html: "pastikan perubahan data sudah benar",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Konfirmasi",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.form.files.splice(indeks, 1, {
            flag: flag,
            id: id,
            id_internal_memo: id_memo,
            path: image_url,
            loadingFiles: true,
            loadingRemove: false,
          });
          this.$axios
            .post(
              "/api/internal-memo/memo/updateFile/" + idFiles,
              formUpdateFile
            )
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  // icon: "success",
                  iconHtml: `
                  <div class="m-2">
                    <i class="fa fa-check"></i>
                  </div>
                `,
                  iconColor: '#a5dc86',
                  html: "Selamat! File Anda berhasil diubah",
                });
                this.formUpdateFile.id_files = null;
                this.formUpdateFile.update_files = [];

                this.getDetail();
              }
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                iconColor: '#dc3545',
                html: "File Anda gagal diubah",
              });
              this.form.files.splice(indeks, 1, {
                flag: flag,
                id: id,
                id_internal_memo: id_memo,
                path: path,
                loadingFiles: false,
                loadingRemove: false,
              });
            });
        }
      });
    },

    //----------------- Remove File Function -----------------//
    showSweetAlertDeleteFile: function (id, indeks) {
      var check = this.form.files.find((item) => item.id === id);
      var content = document.createElement("div");
      content.innerHTML =
        "<p style='text-align: center;'>Anda yakin akan menghapus file ini?\n</p>";

      const Toast = this.$swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });

      this.formDeleteFiles.id_files = id;

      this.$swal({
        html: content,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1e73be",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus",
        closeOnConfirm: false,
        closeOnCancel: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.form.files.splice(indeks, 1, {
            flag: check.flag,
            id: check.id,
            id_internal_memo: check.id_internal_memo,
            path: check.path,
            loadingFiles: false,
            loadingRemove: true,
          });

          this.$axios
            .delete(
              "/api/internal-memo/memo/delete/" + this.formDeleteFiles.id_files
            )
            .then((response) => {
              if (response.status == 200) {
                Toast.fire({
                  // icon: "success",
                  iconHtml: `
                  <div class="m-2">
                    <i class="fa fa-check"></i>
                  </div>
                `,
                  iconColor: '#a5dc86',
                  html: "File berhasil dihapus",
                });
              }
              this.getDetail();
            })
            .catch((error) => {
              console.log(error);
              Toast.fire({
                iconHtml: `
                  <div>
                    <i class="fa fa-times"></i>
                  </div>
                `,
                iconColor: '#dc3545',
                html: "File Anda gagal dihapus",
              });
            });
        }
      });
    },
  },

  created() {
    this.init();
  },
};
</script>

<style lang="scss">
.required label::after {
  content: "*";
  color: #f79483;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload {
  padding: 8px 20px;
  background-color: #808080;
  border: none;
  border-radius: 5px;
  color: white;

  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  transition: 100ms ease-out;
  cursor: pointer;
}

.upload-btn-wrapper:hover {
  border-radius: 5px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem 0 0 0.25rem;
}

.btn-labeled {
  padding-top: unset;
  padding-bottom: unset;
}

div #my-image .expandable-image img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.my-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.multiselect {
  width: 100%;
  display: inline-block;
}
</style>
